.help-colors {
    --primary:#007bff;
    --secondary:#6c757d;
    --success:#28a745;
    --info:#17a2b8;
    --warning:#ffc107;
    --danger:#dc3545;
    --light:#f8f9fa;
    --dark:#343a40;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.modal {
    overflow:auto !important;
}

.modal-header, .modal-title {
    width: 100%
}

.border-dark {
    border-color: #D0D4E0 !important;
}

.list-group-item {
    border: none;
    cursor: default;
}

.list-group-item.active {
    background-color: #4C95E5;
    border: none;
}

.list-group-item:first-child {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}

.form-control {
	border-radius: 0.15rem;
}

textarea.form-control {
    padding: 0;
	width: 100%;
}

.nav-tabs {
    flex-wrap: nowrap;
}

.nav-tabs .nav-link {
    border: none;
}

/*
.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}
*/

textarea.form-control:disabled, textarea.form-control[readonly] {
    background-color: #FFF;
    opacity: 1;
}
select.form-control:disabled, select.form-control[readonly] {
    background-color: #F7F5F7;
    opacity: 1;
}
input.form-control:disabled, input.form-control[readonly] {
    background-color: #F7F5F7;
    opacity: 1;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-item {
    margin: 0;
}

.nav-tabs .nav-item:first-child{
    margin-left: 1rem;
}

.nav-tabs .nav-item:last-child {
    margin-right: 1rem;
}

.nav-link {
    padding: 0.5rem;
    color: #3B4DB3;
}

.col-form-label {
    padding-top: 0;
    padding-bottom: 0;
	font-size: 1rem;
}

.col-form-label-small {
    font-size: 0.8rem;
}

.form-group {
    margin-bottom: 2rem;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.1rem rgba(76, 149, 229, 0.25);
    box-shadow: 0 0 0 0.1rem rgba(76, 149, 229, 0.25);
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #4a4a4a;
    background-color: #ffffff;
    border-color: #d6d6d6;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4C95E5;
    border: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-light{
    color: #4C95E5;
    background-color: #eee;
    border: #add4ff 0 solid;
}

.btn-light:hover{
    color: #fff;
    background-color: #add4ff;
    border: #add4ff 0 solid;
}

.btn-primary {
    background-color: #4c95e5;
}


.table-striped tbody tr:hover {
    background-color: #add4ff;
}

.table-active {
    --bs-table-bg-state: #7dbbff;
}



/* Исправление "улучшены НИПы (карта)..." портит остальные модальные окошки :(
   TODO: исправить по-нормальному (если будем использовать старую орбиту)

@media (min-width: 768px) {
    .modal.modal-fullscreen .modal-dialog .modal-content, .bootstrap-fs-modal .modal .modal-dialog .modal-content .modal-body > div {
        max-height: calc(100vh - 200px);
    }
}
*/