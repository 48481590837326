@page {
    margin: 20mm 0 !important;
}

.printform select.form-control:disabled, select.form-control[readonly],
.printform input.form-control:disabled, input.form-control[readonly] {
    background-color: #FFF;
}

.printform .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-color-type: var(--bs-body-color);
    --bs-table-bg-type: #FFF;
}

.printform .btn,
.printform .btn:disabled {
    border-color: #212529;
    background-color: #FFF;
    color: #212529;
    opacity: 1;
}

.printform .next-level-parameters-group {
    background-color: #FFF;
}

.printform .form-group {
    margin-bottom: 1rem;
}

.printform .d-print-none {
    display: none;
}