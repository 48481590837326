/* Icons */

.fa-sm {
	font-size: 0.66666666em;
	line-height: 1.333333em;
	vertical-align: 15%;
}

/* Small icons */

.spacecraft-name{
	background-image: url('icons/spacecraft.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.orbit-name{
	background-image: url('icons/orbit.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.gc-name{
	background-image: url('icons/gc.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.config-name {
	background-image: url('icons/config.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.project-name {
	background-image: url('icons/project.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.scenario-name {
    background-image: url('icons/scenario.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 40px;
}

.sim-notready-name {
	background-image: url('icons/sim-notready.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.sim-ready-name {
	background-image: url('icons/sim-ready.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.active .sim-ready-name {
	background-image: url('icons/sim-ready-active.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.sim-running-name {
	background-image: url('icons/sim-inprogress.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.sim-evaluating-name {
	background-image: url('icons/sim-evaluating.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.sim-finished-name {
	background-image: url('icons/sim-finished.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.sim-failed-name {
	background-image: url('icons/sim-failed.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.device-name {
	background-image: url('icons/device.svg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

.placeholder-name {
	background-image: url('icons/placeholder-small.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 40px;
}

/* Small icons end. */


/* Large icons: */

/*    - placeholder icons */

.placeholder-tab-icon{
	background-image: url('icons/placeholder-big.jpg');
}
.placeholder-tab-icon:hover{
	background-image: url('icons/placeholder-big.jpg');
}
.active .placeholder-tab-icon{
	background-image: url('icons/placeholder-big.jpg');
}

/*    - spacecrafts icons */

.massinertion-tab-icon{
	background-image: url('icons/massinertion.svg');
}
.massinertion-tab-icon:hover{
	background-image: url('icons/massinertion-hover.svg');
}
.active .massinertion-tab-icon{
	background-image: url('icons/massinertion-active.svg');
}

.spacecraft-type-abstract-tab-icon{
	background-image: url('icons/spacecraft-type-abstract.svg');
}
.spacecraft-type-abstract-tab-icon:hover{
	background-image: url('icons/spacecraft-type-abstract-hover.svg');
}
.active .spacecraft-type-abstract-tab-icon{
	background-image: url('icons/spacecraft-type-abstract-active.svg');
}

.spacecraft-type-cubesat-tab-icon{
	background-image: url('icons/spacecraft-type-cubesat.svg');
}
.spacecraft-type-cubesat-tab-icon:hover{
	background-image: url('icons/spacecraft-type-cubesat-hover.svg');
}
.active .spacecraft-type-cubesat-tab-icon{
	background-image: url('icons/spacecraft-type-cubesat-active.svg');
}

.mass-inertia-template-spherical-tab-icon{
	background-image: url('icons/mass-inertia-template-spherical.svg');
}
.mass-inertia-template-spherical-tab-icon:hover{
	background-image: url('icons/mass-inertia-template-spherical-hover.svg');
}
.active .mass-inertia-template-spherical-tab-icon{
	background-image: url('icons/mass-inertia-template-spherical-active.svg');
}

.mass-inertia-template-cubical-tab-icon{
	background-image: url('icons/mass-inertia-template-cubical.svg');
}
.mass-inertia-template-cubical-tab-icon:hover{
	background-image: url('icons/mass-inertia-template-cubical-hover.svg');
}
.active .mass-inertia-template-cubical-tab-icon{
	background-image: url('icons/mass-inertia-template-cubical-active.svg');
}

.mass-inertia-template-custom-tab-icon{
	background-image: url('icons/mass-inertia-template-custom.svg');
}
.mass-inertia-template-custom-tab-icon:hover{
	background-image: url('icons/mass-inertia-template-custom-hover.svg');
}
.active .mass-inertia-template-custom-tab-icon{
	background-image: url('icons/mass-inertia-template-custom-active.svg');
}

.heat-transfer-tab-icon{
	background-image: url('icons/heat-transfer.svg');
}
.heat-transfer-tab-icon:hover{
	background-image: url('icons/heat-transfer-hover.svg');
}
.active .heat-transfer-tab-icon{
	background-image: url('icons/heat-transfer-active.svg');
}

.program-tab-icon{
	background-image: url('icons/program.svg');
}
.program-tab-icon:hover{
	background-image: url('icons/program-hover.svg');
}
.active .program-tab-icon{
	background-image: url('icons/program-active.svg');
}

.functions-tab-icon{
	background-image: url('icons/functions.svg');
}
.functions-tab-icon:hover{
	background-image: url('icons/functions-hover.svg');
}
.active .functions-tab-icon{
	background-image: url('icons/functions-active.svg');
}

/*    - orbits icons */

.orbitparams-tab-icon{
	background-image: url('icons/orbitparams.svg');
}
.orbitparams-tab-icon:hover{
	background-image: url('icons/orbitparams-hover.svg');
}
.active .orbitparams-tab-icon{
	background-image: url('icons/orbitparams-active.svg');
}

.orbitmapping-tab-icon{
	background-image: url('icons/orbitmapping.svg');
}
.orbitmapping-tab-icon:hover{
	background-image: url('icons/orbitmapping-hover.svg');
}
.active .orbitmapping-tab-icon{
	background-image: url('icons/orbitmapping-active.svg');
}

/*    - simulations icons */

.sim-general-parameters-tab-icon{
	background-image: url('icons/sim-general-parameters.svg');
}
.sim-general-parameters-tab-icon:hover{
	background-image: url('icons/sim-general-parameters-hover.svg');
}
.active .sim-general-parameters-tab-icon{
	background-image: url('icons/sim-general-parameters-active.svg');
}

.environment-orbital-tab-icon{
	background-image: url('icons/environment-orbital.svg');
}
.environment-orbital-tab-icon:hover{
	background-image: url('icons/environment-orbital-hover.svg');
}
.active .environment-orbital-tab-icon{
	background-image: url('icons/environment-orbital-active.svg');
}

.environment-table-tab-icon{
	background-image: url('icons/environment-table.svg');
}
.environment-table-tab-icon:hover{
	background-image: url('icons/environment-table-hover.svg');
}
.active .environment-table-tab-icon{
	background-image: url('icons/environment-table-active.svg');
}

.motion-tab-icon{
	background-image: url('icons/motion.svg');
}
.motion-tab-icon:hover{
	background-image: url('icons/motion-hover.svg');
}
.active .motion-tab-icon{
	background-image: url('icons/motion-active.svg');
}

.sim-results-setup-tab-icon{
	background-image: url('icons/sim-results-setup.svg');
}
.nav-link:not(.disabled) .sim-results-setup-tab-icon:hover{
	background-image: url('icons/sim-results-setup-hover.svg');
}
.active .sim-results-setup-tab-icon{
	background-image: url('icons/sim-results-setup-active.svg');
}

.results-2d-tab-icon{
	background-image: url('icons/results-2d.svg');
}
.results-2d-tab-icon:hover{
	background-image: url('icons/results-2d-hover.svg');
}
.active .results-2d-tab-icon{
	background-image: url('icons/results-2d-active.svg');
}

.sim-3D-tab-icon{
	background-image: url('icons/sim-3D.svg');
}
.nav-link:not(.disabled) .sim-3D-tab-icon:hover{
	background-image: url('icons/sim-3D-hover.svg');
}
.active .sim-3D-tab-icon{
	background-image: url('icons/sim-3D-active.svg');
}

/*   - results icons */

.constellation-tab-icon{
	background-image: url('icons/constellation.svg');
}
.constellation-tab-icon:hover{
	background-image: url('icons/constellation-hover.svg');
}
.active .constellation-tab-icon{
	background-image: url('icons/constellation-active.svg');
}

.groundstation-tab-icon{
	background-image: url('icons/groundstation.svg');
}
.groundstation-tab-icon:hover{
	background-image: url('icons/groundstation-hover.svg');
}
.active .groundstation-tab-icon{
	background-image: url('icons/groundstation-active.svg');
}

.user-defined-tab-icon{
	background-image: url('icons/user-defined.svg');
}
.user-defined-tab-icon:hover{
	background-image: url('icons/user-defined-hover.svg');
}
.active .-tab-icon{
	background-image: url('icons/user-defined-active.svg');
}

.results-export-tab-icon{
	background-image: url('icons/results-export.svg');
}
.results-export-tab-icon:hover{
	background-image: url('icons/results-export-hover.svg');
}
.active .results-export-tab-icon{
	background-image: url('icons/results-export-active.svg');
}


/*    - scenarios icons */

.description-tab-icon{
	background-image: url('icons/description.svg');
}
.description-tab-icon:hover{
	background-image: url('icons/description-hover.svg');
}
.active .description-tab-icon{
	background-image: url('icons/description-active.svg');
}

.init-data-tab-icon{
	background-image: url('icons/init-data.svg');
}
.init-data-tab-icon:hover{
	background-image: url('icons/init-data-hover.svg');
}
.active .init-data-tab-icon{
	background-image: url('icons/init-data-active.svg');
}

.criteria-tab-icon{
	background-image: url('icons/criteria.svg');
}
.criteria-tab-icon:hover{
	background-image: url('icons/criteria-hover.svg');
}
.active .criteria-tab-icon{
	background-image: url('icons/criteria-active.svg');
}

.solutions-tab-icon{
	background-image: url('icons/solutions.svg');
}
.solutions-tab-icon:hover{
	background-image: url('icons/solutions-hover.svg');
}
.active .solutions-tab-icon{
	background-image: url('icons/solutions-active.svg');
}

/*    - solutions icons */
.solution-tab-icon{
	background-image: url('icons/solution.svg');
}
.solution-tab-icon:hover{
	background-image: url('icons/solution-hover.svg');
}
.active .solution-tab-icon{
	background-image: url('icons/solution-active.svg');
}
.solution-result-tab-icon{
	background-image: url('icons/solution-result.svg');
}
.solution-result-tab-icon:hover{
	background-image: url('icons/solution-result-hover.svg');
}
.active .solution-result-tab-icon{
	background-image: url('icons/solution-result-active.svg');
}
/*
.-tab-icon{
	background-image: url('icons/.svg');
}
.-tab-icon:hover{
	background-image: url('icons/-hover.svg');
}
.active .-tab-icon{
	background-image: url('icons/-active.svg');
}
*/

/* Large icons end. */



/* Styles */

html {
	height: 100%;
}

body {
	height: 100%;
	/*overflow: hidden;*/
	color: #717171;
	font-family: 'Open Sans', sans-serif;
}

#root {
	height: 100%;
}

.flex-grow {
	flex: 1;
}

/* sets flex-basis for auto, that helps with keeping size of items similar */
.flex-grow-auto {
	flex: 1 1 auto;
}

.flex-shrink-grow {
	flex: 1 1;
	min-width: 0;
	width: 0;
}

.scroll {
	overflow-y: auto
}

.scroll-parent {
	min-height: 0;
}


a{
	color: #3B4DB3;
}

@media (min-width: 1200px) {
	.modal-xl{
		max-width:1000px
	}
}

h5, .h5 {
    font-size: 1.35rem;
}

h6, .h6 {
    font-size: 1.20rem;
	padding: 20px 0;
}


.toppanel{
	background-color: #EDEEFB;
	padding: 0.2rem 0.5rem;
	color: #92929B;
	font-size: 0.8rem;
}

.toppanel > .nav .nav-link {
	display: inline;
	padding-left: 0;
	padding-right: 0.2rem;
	padding-top: 0;
	padding-bottom: 0;
}

.toppanel > .nav .nav-link:last-child {
	padding-right: 0;
}

.toppanel > .nav > span {
	padding-right: 0.2rem;
}


/*
.workpanel{
	background-color: #fff;
	padding: 0;
}

.workpanel-aftercontent{
	background-color: #F7F5F7;
    padding: 0;
}

.workpanel-content{
	background-color: #fff;
	padding: 0rem 1.25rem 0 1.5rem;
	margin: 0;
}

.workpanel-content textarea{
	padding: 0;
	margin: 0.75rem 0;
	margin-bottom: 1.5rem;
	font-size: 0.8rem;	
	resize: none;
	background-color: #fff;
	color: #000;
	border-color: #fff;
}
*/

.workpanel-gray {
	position: relative;
	/*border-top: 1px solid #C0CBDB;*/
	/*border-bottom: 1px solid #C0CBDB;*/
	width: 100%;
	background-color: #F7F5F7;
	padding: 0.75rem 1.5rem;
}

.workpanel-gray+.workpanel-gray {
	border-top: 0;
}

/*
.workpanel-gray:last-child {
	border-bottom: 0;
}
*/

.parameters-group{
	padding: 0 2rem;
}

.container-fluid > .form-group:last-child {
	margin-bottom: 0;
}

/* corrects fields on white background above tabs (workpanel-gray): */

.container-fluid > .form-group.row {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}
.container-fluid > .form-group.row:first-child {
	padding-top: 1.5rem;
}

.workpanel-gray > .container-fluid > .form-group.row {
	padding-left: 0;
	padding-right: 0;
}
.workpanel-gray > .container-fluid > .form-group.row:first-child {
	padding-top: 0;
}

.workpanel-gray textarea.form-control:disabled, .workpanel-gray textarea.form-control[readonly] {
	background-color: #F7F5F7;
	opacity: 1;
}


/* overlay */

.semitransparent-overlay-container {
	position: relative;
}

/*
.workpanel-content .semitransparent-overlay {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	background-color: rgba(255,255,255, 0.8);
}
*/

.workpanel-gray .semitransparent-overlay {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	background-color: rgba(247,245,247, 0.8);
}

/* List */

.listpanel .active .spacecraft-name, .active .orbit-name, .active .gc,
.listpanel .active .config-name, .active .project-name, .active .device-name,
.listpanel .active .placeholder-name {
	color: #fff;
	-webkit-filter: grayscale(100%) brightness(5);
	filter: grayscale(100%) brightness(5);
}

.controlelement {
	cursor: pointer;
	color: #1259A6;
	font-style: italic;
	margin-right: 5px;
}

span.controlelement:hover,
.controlelement:hover span {
	text-decoration: underline;
}

.linktoobject {
	color: #1259A6;
}

.linktoobject:hover,
.hover-actions-container.linktoobject:hover > .actions {
	color: #1259A6;
	cursor: pointer;
	background: #add4ff;
}

/*
.linktoobject:hover .spacecraft-name, .linktoobject:hover .orbit-name, .linktoobject:hover .gc-name,
.linktoobject:hover .config-name, .linktoobject:hover .project-name,
.linktoobject:hover .placeholder-name {
	color: #fff;
	-webkit-filter: grayscale(100%) brightness(5);
	filter: grayscale(100%) brightness(5);
}
*/

.linktoobject:hover .listpanel-row-elem {
	color: #fff;
	-webkit-filter: grayscale(100%) brightness(5);
	filter: grayscale(100%) brightness(5);
}

.listpanel-header {
	display: flex;
	flex-direction: row;
}

.listpanel-header-elem {
	font-size: 0.7rem;
	font-weight: normal;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: default;
}

.listpanel-row {
	display: flex;
	flex-direction: row;
}

.listpanel-row-elem-actions-container {
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.listpanel-row-elem {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.listpanel-row-elem.wrap {
	white-space: normal;
}

.controlelement .listpanel-row-elem {
	padding-right: 5px;
}

.listpanel-row-elem-actions {
}

.hover-actions-container.list-group-item.active > .actions {
	background-color: #4C95E5;
}


/* Nav Tabs */

.nav-tabs .tab-icon{
	background-repeat: no-repeat;
	background-position: top center;
	width: 90px;
	min-height: 100px;
}

.inner-nav-tabs .tab-icon{
	background-repeat: no-repeat;
	background-position: top center;
	background-size: contain;
	width: 80px;
	height: 80px;
}

.active .tab-icon{
	cursor: default;
}

.nav-tabs .nav-item {
	width: 100px;
}

.nav-tabs .nav-item.lg {
	width: 200px;
}

.nav-tabs .nav-item .nav-link {
	font-size: 0.8rem;
	text-align: center;
	color: #1259A6;
	max-width: 120px;
	word-wrap: break-word;
	cursor: pointer;
}

.nav-tabs .nav-item.lg .nav-link {
	max-width: 220px;
}

.nav-tabs .nav-item .nav-link:hover {
	color: #4C95E5;
}

.nav-tabs .nav-item .nav-link.active {
	color: #4C95E5;
	background-color: transparent;
}

.nav-tabs .nav-item .nav-link.disabled, .nav-tabs .nav-item .nav-link.disabled:hover {
	color: #1259A6;
	cursor: default;
	-webkit-filter: grayscale(100%) brightness(200%);
	filter: grayscale(100%) brightness(200%);
}


.inner-nav-tabs .nav-item .nav-link {
	font-size: 0.8rem;
	text-align: center;
	color: #1259A6;
	padding-left: 0;
}

.inner-nav-tabs .nav-item .nav-link:hover  {
	color: #4C95E5;
	cursor: pointer;
}

.inner-nav-tabs .nav-item .nav-link.active {
	color: #4C95E5;
}

/*!* when tab block is like header and does not contain its tabs *!*/
/*.tab-block+.workpanel-gray,*/
/*.tab-block+div .workpanel-gray {*/
	/*top: -1px;*/
/*}*/

/*!* when tab block is like header and does not contain its tabs *!*/
/*.tab-block>.workpanel-gray,*/
/*.tab-block>div .workpanel-gray {*/
	/*top: -1px;*/
/*}*/


/* Others */

.multibuttons {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.multibuttons button {
	flex: 1 1 auto;
}


.parameters-multibox input {
	margin-bottom: 0.8rem;
}

.parameters-multibox input:not(:first-child) {
	margin-left: 0.8rem;
}

.parameters-multibox button:not(:first-child) {
	margin-left: 0.8rem;
	margin-bottom: 0.8rem;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
	-moz-appearance:textfield;
}

.measureunit{
	color: #9a9a9a;
	font-size: 0.8rem;
}



.parameters-group-heading {
	font-weight: bold;
	font-size: 1rem;	
}



.next-level-parameters-group{
	margin-left: 2rem;
	margin-bottom: 1.25rem;
	padding-top: 0;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 0.25rem;
	border-left: 1px #C0CBDB solid;
	background: #eee;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	color: #1259A6;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: none;
	color: #1259A6;
	cursor: pointer;
}

.card-header:hover{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #add4ff;
    border-bottom: none;
	color: #fff;
	cursor: pointer;
}

.card-header .active .spacecraft-name{
	color: #1259A6;
	filter: none;
	-webkit-filter: none;
}





.messagearea{
	background-color: #fff6dd;
	border-bottom: 1px solid #ffeab1;
	padding-left: 1.25rem;
    padding-right: 1.25rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.messagearea.messagearea-gray {
	background-color: #F7F5F7;
	border-bottom: 1px solid #C0CBDB;
}

.messagearea > div > button,
button.smallbutton {
	font-size: 0.8rem;
    font-weight: normal;
	padding: 0.1rem 0.5rem;
	margin: 0.3rem 0.2rem;	
	border: 1px solid #fff;
}

.messagearea > div > button:disabled,
button.smallbutton:disabled {
	border-color: #d6d6d6;
	color: #4a4a4a;
	background-color: #FFEDDA;
}

.messagearea > div > .nav-link,
.messagearea > div > .dropdown > .nav-link {
	font-size: 0.8rem;
	font-weight: normal;
	padding: 0.15rem 0.5rem;
	margin: 0.3rem 0.2rem;
}

.messagearea > div > .dropdown .nav-link {
	padding-left: 0;
}





.helpicon{
	margin-top: -10px;
	color: #1259A6;
	opacity: 0.2;
}


.helpicon:hover{
	opacity: 1;
}



.field-on-hover{
	border: transparent solid 1px;
}

.field-on-hover:hover{
	border: 1px solid #ced4da;
	border-radius: 0.15rem;
}


/* New */


.configcontent{
	padding: 0 1rem;
}


.configpageblock{
	margin-left: 0.75rem;
	margin-right: 0.75rem;
	border: 1px solid #C0CBDB;
	border-radius: 0.15rem;
	padding: 0.5rem;
	background-color: #fff;
}

.configpageblock .table {
	margin-bottom: 0
}

.configpageblock.clickable{
	cursor: pointer;
}

.configpageblock > .configpageblock-name{
	font-weight: bold;
	font-size: large;
}

.configpageblock:hover img {
	filter:brightness(110%);
}
.configpageblock img {
	max-width: 100%;
	margin-left: 1rem;
	max-height: 176px;
}


/* all presets */

.big-imgs-choice-elem,
.small-imgs-choice-elem,
.small-imgs-choice-elem-centered {
	border: 1px solid #007bff;
	overflow: hidden;
}

.big-imgs-choice-elem .option-name,
.small-imgs-choice-elem .option-name,
.small-imgs-choice-elem-centered .option-name {
	font-size: 1rem;
}

.big-imgs-choice-elem:hover,
.small-imgs-choice-elem:hover,
.small-imgs-choice-elem-centered:hover {
	border: 1px solid #4C95E5;
}

.big-imgs-choice-elem.active,
.small-imgs-choice-elem.active,
.small-imgs-choice-elem-centered.active {
	border: 1px solid #4C95E5;
	background: #4C95E5;
	color: #fff;
}

.big-imgs-choice-elem:hover .big-imgs-choice-pic,
.small-imgs-choice-elem:hover .small-imgs-choice-pic,
.small-imgs-choice-elem-centered:hover .small-imgs-choice-pic {
	filter:brightness(110%);
}

/* customized presets */

.big-imgs-choice .nav-item {
	width: 250px;
}

.big-imgs-choice-elem .option-name {
	height: 48px;
}

.small-imgs-choice-elem:hover .option-name,
.small-imgs-choice-elem-centered:hover .option-name {
	color: #4C95E5;
}

.small-imgs-choice-elem.active .option-name,
.small-imgs-choice-elem-centered.active .option-name {
	color: #fff;
}

.small-imgs-choice-elem .option-name {
	font-weight: bold;
	color: #737373;
	margin-bottom: 0.75rem;
}

.small-imgs-choice-elem-centered .option-name {
	font-weight: bold;
	color: #737373;
	width: 50%;
}

.big-imgs-choice-elem {
	height: 360px;
	padding: 1rem 0.5rem;
	overflow: hidden;
}

.small-imgs-choice-elem {
	width: 220px;
	height: 140px;
	padding: 0.5rem 0.5rem;
	text-align: left;
}

.small-imgs-choice-elem-centered {
	width: 220px;
	height: 140px;
	padding: 0.5rem 0.5rem;
	text-align: left;
	display: flex;
	align-items: center;
}

.big-imgs-choice-pic {
	max-width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	max-height: 176px;
}

.small-imgs-choice-pic {
	max-width: 50%;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

/* preset end */

.icon-hoverable:hover {
	color: #4C95E5;
	cursor: pointer;
}

/* data block */

.datablock {
    margin: 0.25rem;
    border: 1px solid #C0CBDB;
    border-radius: 0.15rem;
    /*padding: 0.5rem;*/
    background-color: #fff;
	display: flex;
	flex-direction: column;
}

.datablock h6 {
    padding: 0.5rem 1rem;
	margin: 0;
    /*font-weight: bold;*/
}

.datablock .data {
    border-top: 1px solid #C0CBDB;
    padding: 0.5rem 1rem;
	background-color: #F7F5F7;
	font-size: 0.8rem;
	flex: 1 1 auto;
}

.datablock .listpanel {
	border-top: 1px solid #C0CBDB;
	padding: 0.5rem 1rem;
}

.datablock .device-name {
	padding-left: 20px;
}

.datablock .list-group-item {
	padding: 0;
}

.datablock .listpanel-row-elem span {
	font-size: 0.8rem;
}

/* Markdown */

.markdown-container img {
	max-width: 100%;
}

.form-control.markdown-container > p,
.form-control.markdown-container > ul {
	margin-bottom: 0;
}


/* Tree */

.tree-container {
	min-width: 0;
}

.tree-node {
	/*margin-left: 4px;*/
	padding: .75rem 1.25rem;
	min-height: 20px;
	min-width: 0;
	color: #1259A6;
	cursor: pointer;
}

.tree-node:hover,
.hover-actions-container.tree-node:hover > .actions {
	background-color: #add4ff;
}
/*
.tree-node.open {
	border-left: 4px solid #64abd4;
	margin-left: 0;
}
*/
.tree-node.selected,
.hover-actions-container.tree-node.selected > .actions {
	background-color: #4C95E5;
}

.tree-node-wrapper {
	min-height: 20px;
	/*display: flex;*/
	/*align-items: center;*/
	min-width: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
}

.selected .tree-node-wrapper {
	color: #fff;
	-webkit-filter: grayscale(100%) brightness(5);
	filter: grayscale(100%) brightness(5);
}

.tree-node-wrapper.empty {
	color: #ccc;
}

.tree-node-icon {
	display: inline-block;
	text-align: center;
	color: #1259A6;
	padding-right: 2px;
}

.tree-node-text {
	user-select: none;
}


/* hover actions container */

.hover-actions-container {
	position: relative;
}

.hover-actions-container > .actions {
	display: none;
	position: absolute;
	right: 0.7em;
	z-index: 1;
}

.hover-actions-container > .actions:hover,
.hover-actions-container:hover > .actions {
	display: block;
}


/* Table Block */

tr.clickable {
	cursor: pointer;
}


/* Tab Block */


/* Drag and Drop */
.drop-target.active {
	background-color: #ACFF83;
}

.drag-source.active {
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: #F0F0F0;
}